import React, { ReactNode, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';
import SliderIcon from '@components/presentational/SliderIcon';
import { PiCaretLeftThin, PiCaretRightThin } from 'react-icons/pi';

interface CarouselProps extends SimpleGridProps {
  children: ReactNode;
  variant?: 'brand' | 'tournesol' | 'tuile' | 'menthe';
  blackIcon?: boolean;
}

export default function Carousel({
  children,
  variant = 'brand',
  blackIcon = false,
  ...props
}: CarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const containerRef = useRef<HTMLElement | null>(null);
  const timeoutRef = useRef(null);

  const nodeLength = useMemo(() => {
    const childrenArray = React.Children.toArray(children);
    return childrenArray.length;
  }, [children]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container || !(container.firstChild instanceof Element)) return;
    const scrollLeft = container.scrollLeft;
    const childWidth = container.firstChild.getBoundingClientRect().width;
    const newIndex = Math.round(scrollLeft / childWidth);
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex);
    }
  };

  const scrollToIndex = (index: number) => {
    if (!containerRef.current) return;
    setActiveIndex(index);
    const container = containerRef.current;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const element = container.children[index];
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const marginLeft = 16;
        const scrollAmount =
          elementRect.left -
          containerRect.left +
          container.scrollLeft -
          marginLeft;
        container.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    }, 200); // Delay before firing auto scrolling
  };

  const scrollLeft = () => {
    const newIndex = Math.max(activeIndex - 1, 0);
    scrollToIndex(newIndex);
  };

  const scrollRight = () => {
    const newIndex = Math.min(activeIndex + 1, nodeLength - 1);
    scrollToIndex(newIndex);
  };

  return (
    <>
      <Box
        width={{ base: '100vw', md: 'revert' }}
        position={{ base: 'relative', md: 'revert' }}
        left={{ base: '-1rem', md: 'revert' }}
      >
        <SimpleGrid
          ref={containerRef}
          display={{ base: 'flex', md: 'grid' }}
          templateColumns={{ md: 'repeat(2, 1fr)' }}
          transition={{
            base: 'transform 0.3s ease-in-out',
            md: 'revert',
          }}
          flexWrap="nowrap"
          overflowX="auto"
          alignItems="stretch"
          pb="1rem"
          mb={{ base: '1rem', md: 0 }}
          scrollBehavior="smooth"
          spacing={{ base: '1rem', md: '1.25rem' }}
          onScroll={handleScroll}
          {...props}
          sx={{
            '& > :first-of-type': {
              marginLeft: { base: '1rem', md: 0 },
            },
            '& > :last-of-type': {
              marginRight: { base: '1rem', md: 0 },
            },
          }}
        >
          {children}
        </SimpleGrid>
      </Box>
      {nodeLength > 1 && (
        <Flex
          justify="space-between"
          display={{ base: 'flex', md: 'none' }}
          my="1rem"
        >
          <Button
            variant="link"
            onClick={scrollLeft}
            aria-label="Aller vers la gauche"
          >
            <SliderIcon
              icon={PiCaretLeftThin}
              color={`${variant}.100`}
              blackIcon={blackIcon}
            />
          </Button>
          <Button
            variant="link"
            onClick={scrollRight}
            aria-label="Aller vers la droite"
          >
            <SliderIcon
              icon={PiCaretRightThin}
              color={`${variant}.100`}
              blackIcon={blackIcon}
            />
          </Button>
        </Flex>
      )}
    </>
  );
}
